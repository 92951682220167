import React, { Component } from 'react';
import { useState, useEffect, useCallback } from "react";
import Unity, { UnityContext } from "react-unity-webgl";
import { logout, isLogged, connect, getBalance, debugSetUnit, getPlayer, createPlayer, mineUpgrade, hireCharacter, tavernStop, mineWork, mineStop, equipItem, unEquipItem, dungeonBattleStart, dungeonBattleEnd, resetPlayer, requestSignIn } from "./NearWallet"
import  buildName from "./Build.js"
import "./App.css";
import ProgressBar from "../src/ProgressBar/ProgressBar";

const errorName = "ContractError";



function App() {

  const unityContext = new UnityContext({
    loaderUrl: "Build/" + buildName + ".loader.js",
    dataUrl: "Build/" + buildName + ".data",
    frameworkUrl: "Build/" + buildName + ".framework.js",
    codeUrl: "Build/" + buildName + ".wasm",
  });

  const [loading, setLoading] = useState(0);
  const [canavasState, setCanvas] = useState("unity-canvas-zero");

  async function checkPlayer(){
    if(window.localStorage.getItem("intro") === "1")
    {
      window.localStorage.removeItem("intro");
      unityContext.send("IntroManager", "ShowIntro")
    }
  
    let playerView; 
    try {
      playerView = await getPlayer();
    }
    catch {
      console.log("fetch error");
    }

    if (playerView === null) {
      window.localStorage.setItem("intro", "1");
      await createPlayer();
      unityContext.send("Contract", "SetAccount", JSON.stringify({isLogged: true}));
    }
    else
    {
      unityContext.send("Contract", "SetAccount", JSON.stringify({isLogged: true}));
    }
  }
  
  useEffect(function () {
    unityContext.on("RequestLogin", async function () {
        
      await connect();

      if(isLogged())
      {
        await checkPlayer();
      }
      else
      {
        unityContext.send("Contract", "SetAccount", JSON.stringify({isLogged: false}));
      }
    });
    
  }, []);


  useEffect(function () {
    unityContext.on("OpenLink", async function (link) {
        
      window.open(link, "_blank");
    });
    
  }, []);

  unityContext.on("RegisterPlayer", async function () {
    await requestSignIn();
    await checkPlayer();
}, []);


  useEffect(function () {
    unityContext.on("progress", function (progression) {
      setLoading(progression*100);
        
    });
    
  }, []);

    // react from unity
    useEffect(function () {
      unityContext.on("GetPlayer", async function () {
  
        console.log("Action: GetPlayer!");

        let playerView;
        let method = "";

        try
        {
          playerView = await getPlayer();
          method = "GetPlayer";
        }
        catch
        {
          method = errorName;
        }

        console.log("onGetPlayer:" + JSON.stringify(playerView, null, 2) );
        
        unityContext.send("Contract", "On", JSON.stringify({method: method, player: playerView}));
  
      });
  
    }, []);

     // react from unity
     useEffect(function () {
      unityContext.on("MineUpgrade", async function (upgradeType) {

        console.log("Action: MineUpgrade!");


        let playerView;
        let method = "";

        try
        {
          playerView =  await mineUpgrade(upgradeType);
          method = "MineUpgrade";
        }
        catch
        {
          method = errorName;
        }

        console.log("processed: mineUpgrade");
        
        unityContext.send("Contract", "On", JSON.stringify({method: method, player: playerView}));
      });
  
    }, []);

    // react from unity
    useEffect(function () {
      unityContext.on("MineWork", async function (unit, amount) {
  
        console.log("Action: MineWork!");

        let playerView;
        let method = "";

        try
        {
          playerView =  await mineWork(unit, amount);
          method = "MineWork";
        }
        catch
        {
          method = errorName;
        }


        console.log("onMineWork");
        unityContext.send("Contract", "On", JSON.stringify({method: method, player: playerView}));
  
      });
  
    }, []);

     // react from unity
     useEffect(function () {
      unityContext.on("MineStop", async function () {

        console.log("Action: MineStop!");
        
        let playerView;
        let method = "";

        try
        {
          playerView = await mineStop();
          method = "MineStop";
        }
        catch
        {
          method = errorName;
        }

        console.log("onMineStop");

        unityContext.send("Contract", "On", JSON.stringify({method: method, player: playerView}));
  
      });
  
    }, []);

      // react from unity
      useEffect(function () {
        unityContext.on("HireCharacter", async function (id) {
    
          console.log("Action: HireCharacter!");

          id = Number.parseInt(id);

        let playerView;
        let method = "";

        try
        {
          playerView = await hireCharacter(id);
          method = "HireCharacter";
        }
        catch
        {
          method = errorName;
        }
  
          console.log("processed: hireCharacter");
         
          unityContext.send("Contract", "On", JSON.stringify({method: method, player: playerView}));

        });
    
      }, []);

       // react from unity
     useEffect(function () {
      unityContext.on("TavernStop", async function () {
        let playerView;
        console.log("Action: TavernStop!");
        let method = "";

        try
        {
          playerView = await tavernStop();
          method = "TavernStop";
        }
        catch
        {
          method = errorName; 
        }
  
        console.log("processed: tavernStop");

        unityContext.send("Contract", "On", JSON.stringify({method: method, player: playerView}));
      });
  
    }, []);

     // react from unity
     useEffect(function () {
      unityContext.on("EquipItem", async function (characterId, itemId) {
  
        console.log("Action: EquipItem!");

        characterId = Number.parseInt(characterId);
        itemId = Number.parseInt(itemId);

        let playerView;
        let method = "";

        try
        {

          playerView= await equipItem(characterId, itemId);//2458274142, 0);//
          method = "EquipItem";
    
        }
        catch
        {
          method = errorName; 
        }
  
        console.log("processed: equipItem");

        unityContext.send("Contract", "On", JSON.stringify({method: method, player: playerView}));
      });
  
    }, []);

     // react from unity
     useEffect(function () {
      unityContext.on("UnEquipItem", async function (characterId, slot) {
  
        console.log("Action: UnEquipItem!");

        characterId = Number.parseInt(characterId);

        let playerView;
        let method = "";

        try
        {

          playerView = await unEquipItem(characterId, slot);//2458274142, 'Weapon');//
          method = "UnEquipItem";
    
        }
        catch
        {
          method = errorName; 
        }
        
  
        console.log("processed: unEquipItem");
        unityContext.send("Contract", "On", JSON.stringify({method: method, player: playerView}));
      });
  
    }, []);

    // react from unity
    useEffect(function () {
      unityContext.on("DungeonBattleStart", async function (dungeonId, character_ids) {
  
        console.log("Action: DungeonBattleStart!" + dungeonId + "  " + character_ids);

        character_ids = JSON.parse(character_ids);

        for (let i = 0; i < character_ids.length; i++) {
          character_ids[i] =  Number.parseInt(character_ids[i]);
        }

        let playerView;
        let method = "";

        try
        {

          playerView = await dungeonBattleStart(dungeonId, character_ids);//"GoblinsFirstTime", [2458274142]);//
          method = "DungeonBattleStart";
    
        }
        catch
        {
          method = errorName; 
        }
  
        console.log("processed: dungeonBattleStart");
        unityContext.send("Contract", "On", JSON.stringify({method: method, player: playerView}));
      });
  
    }, []);


    useEffect(function () {
      unityContext.on("GetBalance", async function () {
  
        console.log("Action: GetBalance");

        let playerView;
        let method = "";

        try
        {

          playerView = await getBalance();//"GoblinsFirstTime", [2458274142]);//
          method = "GetBalance";
    
        }
        catch
        {
          method = errorName;
          console.log("getBalance error") 
        }
  
        console.log("processed: GetBalance");
        unityContext.send("Contract", "On", JSON.stringify({method: method, player: playerView}));
      });
  
    }, []);


     // react from unity
     useEffect(function () {
      unityContext.on("DungeonBattleEnd", async function (dungeonId) {
  
        console.log("Action: DungeonBattleEnd!" + dungeonId  );

        let playerView;
        let method = "";

        try
        {

          playerView = await dungeonBattleEnd(dungeonId);//"GoblinsFirstTime", [2458274142]);//
          method = "DungeonBattleEnd";
    
        }
        catch
        {
          method = errorName; 
        }
  
        console.log("processed: dungeonBattleEnd" + JSON.stringify(playerView, null, 2));

        unityContext.send("Contract", "On", JSON.stringify({method: method, player: playerView}));
      });
  
    }, []);


     // react from unity
     useEffect(function () {
      unityContext.on("DebugSetUnit", async function (unit, amount) {

        console.log("Action: DebugSetUnit!");

        let playerView;
        let method = "";

        try
        {
          playerView = await debugSetUnit(unit, amount);//"GoblinsFirstTime", [2458274142]);//
          method = "DebugSetUnit";
        }
        catch
        {
          method = errorName; 
        }

        console.log("onDebugSetUnit!");
        
       
        unityContext.send("Contract", "On", JSON.stringify({method: method, player: playerView}));
      });
  
    }, []);

     // react from unity
     useEffect(function () {
      unityContext.on("ResetPlayer", async function () {

        console.log("Action: ResetPlayer!");

        let playerView;
        let method = "";

        try
        {
          playerView = playerView = await resetPlayer();
          method = "ResetPlayer";
        }
        catch
        {
          method = errorName; 
        }
        
        console.log("onResetPlayer!");
        
        unityContext.send("Contract", "On", JSON.stringify({method: method, player: playerView}));

        //window.location.reload();
  
      });
  
    }, []);

  useEffect(function () {
    unityContext.on("loaded", async () => setCanvas("unity-canvas-default")
    );
  }, []);

  
  document.addEventListener('keydown', (e) => {
    if (e.code === "Escape"){
      setCanvas('unity-canvas-default');
    }
  });

  function setFullScreen(){
    let element = document.getElementById("unity-canvas-1");
    if(element.requestFullscreen) {
      element.requestFullscreen();
    } else if(element.webkitrequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if(element.mozRequestFullscreen) {
      element.mozRequestFullScreen();
    }
  
  }
 

  return (
    <div className={"wrapper"}>
      <Unity unityContext={unityContext} className={canavasState} />


      {loading < 100? 
      <div className='fake-unity-frame'><ProgressBar bgcolor={"#6a1b9a"} completed={loading}/></div>
        :
        null
      }
      
      {canavasState==="unity-canvas-default" ?
         <div className='btn-container'>
         <button onClick={setFullScreen} className={"btn"}>full screen</button>
         <button onClick={logout} className={"btn1"}>logout</button>
       </div>
        
        :
        null
      }
      
    </div>);
}

export default App;
