import "regenerator-runtime/runtime"
import * as nearAPI from "near-api-js"
import Big from 'big.js'


const contractName = "dev-1637702458471-75231541124253";

const nearConfig = {
    networkId: 'testnet',
    nodeUrl: 'https://rpc.testnet.near.org',
    contractName,
    walletUrl: 'https://wallet.testnet.near.org',
    helperUrl: 'https://helper.testnet.near.org'
}
let near
let walletConnection
let contract

const BOATLOAD_OF_GAS = Big(10).times(10 ** 13).toFixed();


// TODO allowance
async function refreshAllowance() {
    alert("You're out of access key allowance. Need sign in again to refresh it")
    await logout()
    await requestSignIn()
}


export async function requestSignIn () {

    if(walletConnection.isSignedIn())
    {
        return;
    }

    const appTitle = 'NEAR Dungeon'
    await walletConnection.requestSignIn(contractName, appTitle)
  
}



export async function connect() {

    near = await nearAPI.connect({
        deps: {
            keyStore: new nearAPI.keyStores.BrowserLocalStorageKeyStore()
        },
        ...nearConfig
    })

    walletConnection = new nearAPI.WalletConnection(near)

    contract = await new nearAPI.Contract(walletConnection.account(), contractName, {
        viewMethods: [
            'get_status',
            'get_player'
          ],
          changeMethods: [
            'create_player',
            'reset_player',
            'hire_character',
            'tavern_stop',
            'equip_item',
            'unequip_item',
            'dungeon_battle_start',
            'dungeon_battle_end',
            'mine_work',
            'mine_upgrade',
            'mine_stop',
            'debug_set_unit'
          ],
        sender: walletConnection.getAccountId()
    })

    console.log(walletConnection.getAccountId());
}


export async function login() {
       await connect();
       await requestSignIn(); 
}

export function isLogged(){
    return walletConnection.isSignedIn();
}


export function logout() {
    walletConnection.signOut();
    window.location.reload(false);
    //window.location.replace(window.location.origin + window.location.pathname)
}

export async function mineUpgrade(upgradeType) {
    let resp = await contract.mine_upgrade({"event" : upgradeType}, BOATLOAD_OF_GAS);
    return resp;
}

//amount str
export async function mineWork(unit, amount) {
    let resp = await contract.mine_work({'unit': unit, 'amount':amount}, BOATLOAD_OF_GAS);
    return resp;
}

export async function mineStop() {
    let resp = await contract.mine_stop({}, BOATLOAD_OF_GAS);
    return resp;
}

export async function createPlayer()
{
    let resp = await contract.create_player({}, BOATLOAD_OF_GAS, "250000000000000000000000");
    
    return resp;
}

export async function getPlayer()
{
    let resp = await contract.get_player({player_id: walletConnection.getAccountId()});
    return resp;
}

export async function getStatus()
{
    let resp = await contract.get_status();
    return resp;
} 

export async function hireCharacter(id) {

    let resp = await contract.hire_character({'id': id}, BOATLOAD_OF_GAS);
    return resp;
}

export async function tavernStop() {

    let resp = await contract.tavern_stop({},BOATLOAD_OF_GAS);
    return resp;
}

export async function equipItem(characterId, itemId) {

    let resp = await contract.equip_item({'character_id': characterId, 'item_id': itemId}, BOATLOAD_OF_GAS);
    return resp;
}

export async function unEquipItem(characterId, slot) {

    let resp = await contract.unequip_item({'character_id': characterId, 'slot': slot}, BOATLOAD_OF_GAS);
    return resp;
}

export async function dungeonBattleStart(dungeonId, character_ids) {

    let resp = await contract.dungeon_battle_start({'event': dungeonId, 'character_ids': character_ids}, BOATLOAD_OF_GAS);
    return resp;
}

export async function dungeonBattleEnd(dungeonId) {

    let resp = await contract.dungeon_battle_end({'event': dungeonId}, BOATLOAD_OF_GAS);
    return resp;
}

export async function resetPlayer() {

    let resp = await contract.reset_player({}, BOATLOAD_OF_GAS, '100000000000000000000000');
    return resp;
}


export async function debugSetUnit(unit, amount) {
    let resp = await contract.debug_set_unit({'unit': unit, 'amount': amount}, BOATLOAD_OF_GAS);
    return resp;
}

export async function getBalance()
{
    const account = await near.account(walletConnection.getAccountId());
    let balance = await account.getAccountBalance();
    return (Number.parseInt(balance.available)/10**24).toFixed(1);
}
